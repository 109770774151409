
<script setup>
    import { ref, watch, computed, onMounted, onBeforeUnmount } from "vue";
    
    const props = defineProps(["modelValue", "side"]);
    const emit = defineEmits(["update:modelValue"]);

    const drawer = ref(null);

    watch(() => props.modelValue, () => {
        if (props.modelValue) {
            // show drawer
            drawer.value.style.transition = "all 300ms ease-in-out";
            drawer.value.style.transform = "translate(0%, 0%)";
        } else {
            // hide drawer
            drawer.value.style.transition = "all 300ms ease-in-out";
            drawer.value.style.transform = "translate(100%, 0%)";
        }
    });

    const state = {};

    function onTouchStart(e) {
        state.x = 0;
        state.y = 0;

        state.vel_x = 0;
        state.vel_y = 0;
        
        state.prev_x = e.touches[0].clientX;
        state.prev_y = e.touches[0].clientY;
        state.prev_t = e.timeStamp;

        state.cancel = false;

        state.firstFrame = true;

        if (!props.modelValue) {
            state.cancel = true;
        }
    }

    function onTouchMove(e) {
        // precalculate
        const dif_x = e.touches[0].clientX - state.prev_x;
        const dif_y = e.touches[0].clientY - state.prev_y;

        if (state.firstFrame) {
            // cancel if vertical swipe
            if (Math.abs(dif_x) < Math.abs(dif_y)) {
                state.cancel = true;
            }
        }

        if (state.cancel) {
            return;
        } else {
            e.preventDefault();
        }

        // update state
        state.x += dif_x;
        state.y += dif_y;

        state.vel_x = dif_x/(e.timeStamp - state.prev_t);
        state.vel_y = dif_y/(e.timeStamp - state.prev_t);

        state.prev_x = e.touches[0].clientX;
        state.prev_y = e.touches[0].clientY;
        state.prev_t = e.timeStamp;

        if (state.x < 0) {
            state.x = 0;
        }

        // update visual
        let offset_x = state.x;

        drawer.value.style.transition = "all 0ms";
        drawer.value.style.transform = `translate(calc(${offset_x}px), 0%)`;

        state.firstFrame = false;
    }

    function onTouchEnd(e) {
        if (state.cancel) {
            return;
        }
        if (state.vel_x > 0.5 || (state.vel_x >= 0 && state.x >= drawer.value.clientWidth/2)) {
            emit("update:modelValue", false);
        } else {
            drawer.value.style.transition = "all 300ms ease-in-out";
            drawer.value.style.transform = "translate(0%, 0%)";
        }
    }

    onMounted(() => {
        drawer.value.addEventListener("touchstart", onTouchStart);
        drawer.value.addEventListener("touchmove", onTouchMove);
        drawer.value.addEventListener("touchend", onTouchEnd);
    });

    onBeforeUnmount(() => {
        drawer.value.removeEventListener("touchstart", onTouchStart);
        drawer.value.removeEventListener("touchmove", onTouchMove);
        drawer.value.removeEventListener("touchend", onTouchEnd);
    });
</script>

<template>
    <div>
        <teleport to="body">
            <div>
                <!-- backdrop -->
                <div class="c-drawer-backdrop" :class="{ 'show': modelValue }" @click="emit('update:modelValue', false)">
                </div>

                <!-- drawer -->
                <div ref="drawer" class="c-drawer-modal d-flex flex-column" :class="{ 'show': modelValue }">
                    <slot name="header">
                        <div class="flex-shrink-0 d-flex" style="height: 3.5rem;">
                            <div class="flex-grow-1 ms-3 d-flex align-items-center" style="font-size: 1.25em; font-weight: bold;">
                                <slot name="title">{{ $t("Title") }}</slot>
                            </div>
                            <slot name ="buttons"></slot>
                            <a class="flex-shrink-0 d-flex justify-content-center align-items-center" style="height: 100%; aspect-ratio: 1; color: rgb(60%, 60%, 60%); text-decoration: none;" @click="emit('update:modelValue', false)">
                                <i class="bi bi-x" style="font-size: 1.5em;" />
                            </a>
                        </div>
                    </slot>
                    <div class="flex-grow-1" style="position: relative; overflow-y: auto; overflow-x: hidden; overscroll-behavior: none;">
                        <slot name="body" />
                    </div>
                </div>
            </div>
        </teleport>
    </div>
</template>

<style scoped>
    .c-drawer-backdrop {
        z-index: 500;
        position: fixed;
        inset: 0;

        display: flex;
        flex-direction: column;

        background-color: rgb(0%, 0%, 0%, 25%);

        transition: all 300ms ease-in-out;

        opacity: 0%;
        pointer-events: none;
    }

    .c-drawer-backdrop.show {
        opacity: 100%;
        pointer-events: auto;
    }

    .c-drawer-modal {
        z-index: 510;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;

        width: min(80vw, 400px);

        background-color: rgb(96%, 96%, 96%);

        transform: translate(100%, 0%);
        box-shadow: none;
    }

    .c-drawer-modal.show {
        transform: translate(0%, 0%);
        box-shadow: 0 0 8px 8px rgb(0%, 0%, 0%, 15%);
    }
</style>
